.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #FCF8F3;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: 'RobotoSlab';
  src: url('/public/assets/fonts/RobotoSlab-VariableFont_wght.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: 'RobotoSlab', sans-serif;
}

/* Dynamic Keepa Graph */
.round-checkbox {
  appearance: none;
  background-color: #E3D0DF;
  margin: 0.5em;
  font: inherit;
  color: currentColor;
  width: 1.5em;
  height: 1.5em;
  border: 0.05em solid currentColor;
  border-radius: 50%;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
}

.round-checkbox::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--form-control-color);
  background-color: #8B3C7E;
  transform-origin: bottom left;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
} 

.round-checkbox:checked::before {
  transform: scale(1);
}

.keepagrapha2w {
  cursor: url('/public/assets/settings-icon.png'), auto !important;
}
.keepagrapha2w:hover {
  transform: scale(2) !important;
  z-index: 1000 !important;
}

.roadmap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  margin: 0 auto;
  max-width: 1000px;
}

.roadmap-step {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 15px;
  width: 200px;
  position: relative;
  height: 150px;
}

.roadmap-step::after {
  content: '';
  position: absolute;
  top: 50%;
  right: -20px;
  width: 20px;
  height: 4px;
  background-color: #ddd;
  z-index: 0;
}

.roadmap-step:last-child::after {
  display: none;
}

.step-icon {
  width: 50px;
  height: 50px;
  background: #ccc;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.roadmap-step.completed .step-icon {
  background: #4caf50;
}

.step-content h3 {
  margin: 0 0 5px;
  font-size: 16px;
  color: #333;
  text-align: center;
}

.step-content p {
  margin: 0;
  font-size: 14px;
  color: #666;
  text-align: center;
}

@media (max-width: 800px) {
  .roadmap {
    flex-direction: column;
    gap: 10px;
    height: 220px;
    overflow-y: scroll;
    padding: 10px;
    box-shadow: 0px 0px 10px #f8a100;
  }

  .roadmap-step::after {
    top: auto;
    right: auto;
    left: 50%;
    bottom: -20px;
    width: 4px;
    height: 20px;
  }
}

.modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.modal.is-active {
  display: flex;
}

.modal-background {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
}

.modal-content {
  background: #fff;
  border-radius: 12px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  padding: 20px;
  position: relative;
  overflow-y: auto;
  max-height: 80%;
  transition: transform 0.2s ease-in-out;
  overflow-x: hidden;
}

.columns {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.column {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  width: 20%;
}

.transparent-white {
  mix-blend-mode: multiply;
}

.floatingAccept {
  position: sticky;
  top: 85vh;
  z-index: 999;
  width: 30vw;
  margin: auto;
  justify-content: center;
  margin-bottom: -3vh;
  display: flex;
  flex-direction: row;
}

.button {
  cursor: pointer;
  border-radius: 20px;
  width: 25%;
}

.button:hover {
  box-shadow: 0px 0px 10px 0px black !important;
}